<template>
  <div class="h-screen">
    <AppLoadingState v-if="!auth.context" />

    <div v-else class="flex flex-col h-screen">
      <div class="flex-grow p-2">
        <slot />
      </div>

      <div class="hidden md:block bg-cyan-950 h-16 shadow">
        <div class="flex justify-center items-center h-16 space-x-8">
          <div class="flex">
            <BottomMenuLink
              :icon="Squares2X2Icon"
              :active="route.path === '/'"
              @click="navigate('/')"
            />

            <BottomMenuLink :icon="BellIcon" @click="notifications.show = true">
              <div v-if="auth.unreadNotificationCount" class="relative">
                <div
                  class="animate-ping absolute h-6 w-6 rounded-full bg-orange-700"
                ></div>

                <div
                  class="relative h-6 w-6 rounded-full bg-orange-700 flex items-center justify-center text-white text-sm"
                >
                  {{ auth.unreadNotificationCount }}
                </div>
              </div>
            </BottomMenuLink>

            <BottomMenuLink
              :icon="ArrowLeftStartOnRectangleIcon"
              @click="signOut"
            />
          </div>

          <BottomMenuLink
            v-for="bottomMenuItem in bottomMenuItems"
            :key="bottomMenuItem.id"
            :active="
              bottomMenuItem.match
                ? bottomMenuItem.match(route.path)
                : route.path.startsWith(bottomMenuItem.to)
            "
            :icon="bottomMenuItem.icon"
            :to="bottomMenuItem.to"
            @click="bottomMenuItem.onClick"
          >
            {{ $t(bottomMenuItem.text) }}
          </BottomMenuLink>
        </div>
      </div>

      <div
        aria-live="assertive"
        class="z-20 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
          <transition-group
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <UINotification
              v-for="notification in notifications.notifications"
              :key="notification.id"
              :headline="notification.headline"
              :body="notification.body"
              :level="notification.level"
              @close="notifications.remove(notification)"
            />
          </transition-group>
        </div>
      </div>

      <ConfirmModal />

      <NotificationDrawer
        :open="notifications.show"
        @close="notifications.show = false"
      />
    </div>
  </div>
</template>

<script setup>
import {
  ArrowLeftStartOnRectangleIcon,
  BellIcon,
} from "@heroicons/vue/24/outline/index";
import { useAuth } from "~/stores/auth.js";
import { UINotification } from "@ultrafedt/components";
import { useNotifications } from "~/stores/notifications.js";
import ConfirmModal from "~/components/Modals/ConfirmModal.vue";
import NotificationDrawer from "~/components/Drawers/NotificationDrawer.vue";
import BottomMenuLink from "~/components/BottomMenuLink.vue";
import { useMenu } from "~/composables/useMenu.js";
import { Squares2X2Icon } from "@heroicons/vue/24/outline/index.js";
import { useOrderStore } from "~/stores/orderStore.js";
import { useModal } from "~/stores/modal.js";
import { useIdle } from "@vueuse/core";

useHead({
  htmlAttrs: {
    class: "h-full bg-orange-50",
  },
  bodyAttrs: {
    class: "h-full w-full",
  },
  titleTemplate: "%s - Ultrafedt POS Terminal",
});

const auth = useAuth();
const notifications = useNotifications();
const route = useRoute();
const { t } = useI18n();

const bottomMenuItems = computed(() => {
  return auth.bottomMenuItems.map((bottomMenuItem) =>
    useMenu().find((existing) => existing.id === bottomMenuItem.target),
  );
});

const loadContext = async () => {
  if (!document.hidden) {
    await auth.loadContext();
  }
};

const checkDraftOrderLines = (callback) => {
  if (!useOrderStore().draftOrderLines.length) {
    return callback();
  }

  useModal().ask({
    headline: t("You have unsaved order lines"),
    text: t(
      "You have unsaved order lines. Are you sure that you want to discard the order lines?",
    ),
    callback,
  });
};

const navigate = (to) => {
  checkDraftOrderLines(() => navigateTo(to));
};

const signOut = () => {
  checkDraftOrderLines(auth.signOut);
};

onMounted(() => document.addEventListener("visibilitychange", loadContext));
onBeforeUnmount(() =>
  document.removeEventListener("visibilitychange", loadContext),
);

if (auth.idleLimit !== null) {
  const { idle } = useIdle(auth.idleLimit * 1000);

  watch(idle, async (idleValue) => {
    if (idleValue) {
      await auth.signOut();
    }
  });
}
</script>
